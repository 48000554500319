var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full d-flex justify-space-between flex-column"},[_c('div',[_c('h2',{staticClass:"primary--text text-center mb-2 d-none d-md-block"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_initial_contribution.i18n_namespace) + ".title")))+" ")]),_c('p',{class:{
        'text-text mb-8 primary--text': true,
        'text-center': !_vm.contract_savings_initial_contribution.small_screen,
      }},[_vm._v(" "+_vm._s(_vm.$tc( ((_vm.contract_savings_initial_contribution.i18n_namespace) + ".description"), null, { name: _vm.contract_savings_initial_contribution.user_name } ))+" ")]),_c('h6',{staticClass:"primary---text mb-3 mb-md-7"},[_vm._v(" "+_vm._s(_vm.$t( ((_vm.contract_savings_initial_contribution.i18n_namespace) + ".initial_contribution.label") ))+" ")]),_c('v-form',{class:{
        'px-5 py-4 py-md-0 rounded mb-5 d-md-10': true,
        'elevation-1': _vm.contract_savings_initial_contribution.small_screen,
      },model:{value:(_vm.contract_savings_initial_contribution.form_validity),callback:function ($$v) {_vm.$set(_vm.contract_savings_initial_contribution, "form_validity", $$v)},expression:"contract_savings_initial_contribution.form_validity"}},[_c('label',{staticClass:"inactive-blue--text"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_initial_contribution.i18n_namespace) + ".unique_premium.label")))+" ")]),_c('v-text-field',{staticClass:"v-text-field--blue pt-0",attrs:{"name":"unique_premium","color":"light-blue","value":"$500.00 MXN","disabled":""}}),_c('label',{staticClass:"inactive-blue--text"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_initial_contribution.i18n_namespace) + ".initial_contribution.label")))+" ")]),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:({ prefix: '$', suffix: ' MXN' }),expression:"{ prefix: '$', suffix: ' MXN' }"}],staticClass:"v-text-field--blue pt-0",attrs:{"name":"initial_contribution","color":"light-blue","rules":_vm.contract_savings_initial_contribution.rules.initial_contribution},model:{value:(_vm.contract_savings_initial_contribution.initial_contribution),callback:function ($$v) {_vm.$set(_vm.contract_savings_initial_contribution, "initial_contribution", $$v)},expression:"contract_savings_initial_contribution.initial_contribution"}})],1),_c('small',{staticClass:"dark-grey--text"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_initial_contribution.i18n_namespace) + ".insurance")))+" ")])],1),_c('v-row',{attrs:{"align":"end"}},[_c('contract-savings-actions',{attrs:{"service-name":'allianz',"is-loading":_vm.contract_savings_initial_contribution.is_loading,"btn-disabled":!_vm.contract_savings_initial_contribution.form_validity
            || _vm.contract_savings_initial_contribution.is_loading},on:{"update:isLoading":function($event){return _vm.$set(_vm.contract_savings_initial_contribution, "is_loading", $event)},"update:is-loading":function($event){return _vm.$set(_vm.contract_savings_initial_contribution, "is_loading", $event)},"update:btnDisabled":function($event){return _vm.$set(!_vm.contract_savings_initial_contribution.form_validity
            || _vm.contract_savings_initial_contribution, "is_loading", $event)},"update:btn-disabled":function($event){return _vm.$set(!_vm.contract_savings_initial_contribution.form_validity
            || _vm.contract_savings_initial_contribution, "is_loading", $event)},"prevStep":_vm.prevStep,"nextStep":_vm.nextStep}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }